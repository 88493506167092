<template>
  <div
    @click="openMoreGames(false)"
    class="w-full h-full flex flex-col z-10 bg-zinc-950"
  >
    <div
      class="w-full h-max flex items-end justify-between px-3 md:px-[30px] pt-2 pb-1.5 border-zinc-700"
    >
      <div class="flex flex-wrap items-end justify-center gap-3.5 relative">
        <div :key="dateKey" class="flex flex-col pb-1 w-full md:w-max">
          <div class="flex justify-between">
            <span class="text-zinc-300 whitespace-nowrap text-sm">
              {{ $t("charts.dateRange") }}
            </span>
          </div>
          <!-- <v-date-picker v-model.range="dateRange" is-dark mode="dateTime" color="pink" is24hr :max-date="new Date()" :locale="appLanguage.long">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center gap-2">
                <input
                  v-if="ready"
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                />
                <div v-else class="bg-zinc-800 animate-pulse h-8 rounded-md w-full md:w-[146px]"></div>
                <font-awesome-icon :icon="['fas', 'arrow-right']" class="h-4 text-white" />
                <input
                  v-if="ready"
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  class="px-2 py-1 w-full md:w-[146px] rounded focus:outline-none bg-zinc-800 text-white"
                />
                <div v-else class="bg-zinc-800 animate-pulse h-8 rounded-md w-full md:w-[146px]"></div>
              </div>
            </template>
          </v-date-picker> -->
          <div class="w-[22rem]">
            <DatePicker type="custom" />
          </div>
        </div>
        <div class="flex items-center gap-1.5 h-full py-1 mb-0.5">
          <button
            @click="tempLinesFormat = 'hour'"
            class="pt-1 pb-0.5 px-2 rounded-sm fugaz uppercase text-sm"
            :class="
              tempLinesFormat == 'hour'
                ? 'bg-zinc-800 text-orange-600'
                : 'bg-zinc-800 text-white xl:hover:bg-opacity-80'
            "
          >
            {{ $t("charts.hour") }}
          </button>
          <button
            @click="tempLinesFormat = 'day'"
            class="pt-1 pb-0.5 px-2 rounded-sm fugaz uppercase text-sm disabled:opacity-25"
            :class="
              tempLinesFormat == 'day'
                ? 'bg-zinc-800 text-orange-600'
                : 'bg-zinc-800 text-white xl:hover:bg-opacity-80'
            "
          >
            {{ $t("charts.day") }}
          </button>
          <button
            @click="tempLinesFormat = 'month'"
            class="pt-1 pb-0.5 px-2 rounded-sm fugaz uppercase text-sm disabled:opacity-25"
            :class="
              tempLinesFormat == 'month'
                ? 'bg-zinc-800 text-orange-600'
                : 'bg-zinc-800 text-white xl:hover:bg-opacity-80'
            "
          >
            {{ $t("charts.month") }}
          </button>
        </div>
      </div>
    </div>
    <div
      class="flex flex-wrap justify-center md:justify-start px-3 md:px-[30px]"
    >
      <div class="flex gap-1.5 items-center">
        <div
          class="w-full md:w-max"
          :class="
            appLanguage.long == 'en-GB' ? 'min-w-[255px]' : 'min-w-[270px]'
          "
        >
          <Multiselect
            mode="multiple"
            v-model="selectedGames"
            :options="allGames"
            label="name"
            value-prop="id"
            :placeholder="$t('charts.specific.selectOneGame')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :multiple-label="
              (values) => {
                if (this.selectedGames.length === this.allGames.length) {
                  return `All Games Selected`;
                } else {
                  return `${values.length} Games selected`;
                }
              }
            "
            :loading="!ready"
          />
        </div>
        <button
          @click="selectAllGames"
          class="text-sm font-semibold fugaz uppercase px-2 pt-1 pb-0.5 bg-zinc-800 xl:hover:bg-opacity-80 rounded-sm"
          :class="
            allGamesSelected
              ? 'text-orange-600'
              : 'text-white xl:hover:text-orange-600'
          "
        >
          All
        </button>
      </div>
      <div
        v-if="userRoles.isLudusAdmin || userRoles.isLudusManager"
        class="flex gap-1.5 items-center"
      >
        <div
          class="w-full md:w-max"
          :class="
            appLanguage.long == 'en-GB' ? 'min-w-[235px]' : 'min-w-[260px]'
          "
        >
          <Multiselect
            mode="multiple"
            v-model="selectedPlatforms"
            :options="allPlatforms"
            label="name"
            value-prop="id"
            :placeholder="$t('charts.specific.filterByPlatform')"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :multiple-label="(values) => `${values.length} Platforms selected`"
            :loading="!ready"
          />
        </div>
        <button
          @click="selectAllPlatforms"
          class="text-sm font-semibold fugaz uppercase px-2 pt-1 pb-0.5 bg-zinc-800 xl:hover:bg-opacity-80 rounded-sm"
          :class="
            allPlatformsSelected
              ? 'text-orange-600'
              : 'text-white xl:hover:text-orange-600'
          "
        >
          All
        </button>
      </div>
      <div class="px-3 flex items-cener gap-1 mb-1">
        <button
          @click="changeSessionsType('fun')"
          class="font-bold xl:hover:text-white group flex items-center"
          :class="[
            sessionsType == 'fun' || sessionsType == 'all'
              ? 'text-white'
              : 'text-zinc-400',
          ]"
        >
          <span class="text-lg">{{ $t("charts.fun") }}</span>
          <font-awesome-icon :icon="['fas', 'gift']" class="h-4 pl-1" />
        </button>
        <label
          class="gap-2 items-center cursor-pointer relative px-2 rounded-md h-max"
        >
          <input
            type="checkbox"
            class="sr-only outline-none"
            @input="changeSessionsType()"
          />
          <div
            :class="[
              'block w-14 h-8 rounded-full relative transition-all duration-300 bg-zinc-800',
            ]"
          >
            <div
              class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
              :class="{
                'translate-x-6': sessionsType === 'real',
                'translate-x-3': sessionsType === 'all',
              }"
            ></div>
          </div>
        </label>
        <button
          @click="changeSessionsType('real')"
          class="font-bold xl:hover:text-white group flex items-center"
          :class="[
            sessionsType == 'real' || sessionsType == 'all'
              ? 'text-white'
              : 'text-zinc-400',
          ]"
        >
          <span class="text-lg">{{ $t("charts.real") }}</span>
          <font-awesome-icon :icon="['fas', 'euro-sign']" class="h-4 pl-1" />
        </button>
      </div>
      <div class="px-3 flex items-center gap-1 mb-1">
        <button
          @click="isOpen = false"
          class="text-lg font-semibold"
          :class="
            isOpen === false || isOpen === null
              ? 'text-white'
              : 'text-zinc-400 xl:hover:text-white'
          "
        >
          {{ $t("sessionsList.closed") }}
        </button>
        <label
          class="flex gap-2 items-center cursor-pointer relative px-2 rounded-md h-max"
        >
          <input
            type="checkbox"
            class="sr-only outline-none"
            @input="changeIsOpen"
          />
          <div
            :class="[
              'block w-14 h-8 rounded-full relative transition-all duration-300',
              {
                'bg-sky-500': isOpen === true,
                'bg-green-500': isOpen === false,
                'bg-zinc-800': isOpen === null,
              },
            ]"
          >
            <div
              class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"
              :class="{
                'translate-x-6': isOpen === true,
                'translate-x-3': isOpen === null,
              }"
            ></div>
          </div>
        </label>
        <button
          @click="isOpen = true"
          class="text-lg font-semibold"
          :class="
            isOpen === true || isOpen === null
              ? 'text-white'
              : 'text-zinc-400 xl:hover:text-white'
          "
        >
          {{ $t("sessionsList.open") }}
        </button>
      </div>
      <button
        @click="getTotals(true)"
        :disabled="loading || selectedGames.length == 0"
        class="hidden md:block px-3 pt-1 pb-[3px] text-white bg-zinc-700 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50 mb-1"
        :class="
          !loading &&
          selectedGames.length !== 0 &&
          'hover:text-orange-600 xl:hover:bg-zinc-800'
        "
      >
        {{ $t("charts.submit") }}
      </button>
    </div>
    <!-- mobile submit -->
    <div class="flex md:hidden items-center justify-center gap-1 w-full h-max">
      <button
        @click="getTotals(true)"
        :disabled="loading || selectedGames.length == 0"
        class="px-3 pt-1 pb-[3px] text-orange-600 bg-zinc-800 rounded-md uppercase fugaz text-base transition-all duration-100 disabled:opacity-50"
      >
        {{ $t("charts.submit") }}
      </button>
    </div>
    <div
      class="w-full h-full flex flex-col gap-y-2 px-3 md:px-7 pb-[60px] xl:pb-4"
    >
      <div class="w-full flex flex-col bg-zinc-950 rounded-md pb-2 text-white">
        <span class="text-sm text-zinc-300 mb-0.5">Values type</span>
        <div class="xl:flex items-center justify-between">
          <div
            class="flex items-center flex-wrap justify-center md:justify-start gap-2.5"
          >
            <button
              @click="changeChartsValuesType('bet')"
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'bet'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              Bet
            </button>
            <button
              @click="changeChartsValuesType('win')"
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'win'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              Win
            </button>
            <button
              @click="changeChartsValuesType('grosswin')"
              v-if="!hideRealWins"
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'grosswin'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              Gross win
            </button>
            <button
              @click="changeChartsValuesType('netwin')"
              v-if="
                (userRoles.isLudusAdmin || userRoles.isPlatformAdmin) &&
                !hideRealWins
              "
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'netwin'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              Net win
            </button>
            <button
              @click="changeChartsValuesType('due')"
              v-if="
                (userRoles.isLudusAdmin || userRoles.isPlatformAdmin) &&
                !hideRealWins
              "
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'due'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              Due
            </button>
            <button
              @click="changeChartsValuesType('sessionsTotals')"
              v-if="!hideRealWins"
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'sessionsTotals'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              {{ $t("charts.sessions") }}
            </button>
            <button
              @click="changeChartsValuesType('rounds')"
              v-if="!hideRealWins"
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'rounds'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              {{ $t("charts.rounds") }}
            </button>
            <button
              @click="changeChartsValuesType('usersCount')"
              v-if="!hideRealWins"
              class="pt-1.5 pb-[3px] px-3 rounded-md text-lg fugaz uppercase bg-zinc-800 transition-all duration-200"
              :class="
                selectedValuesType == 'usersCount'
                  ? 'text-orange-600'
                  : 'text-zinc-400'
              "
            >
              {{ $t("charts.players") }}
            </button>
            <!-- <button @click="changeChartsValuesType('netWin')" v-if="!userPlatform && !hideRealWins" class="py-1.5 px-3 rounded-md text-lg fugaz uppercase bg-zinc-900"
              :class="selectedValuesType == 'netWin' ? 'bg-zinc-600' : 'bg-zinc-900'">
              Net win
            </button>
            <button @click="changeChartsValuesType('due')" v-if="!userPlatform && !hideRealWins" class="py-1.5 px-3 rounded-md text-lg fugaz uppercase bg-zinc-900"
              :class="selectedValuesType == 'due' ? 'bg-zinc-600' : 'bg-zinc-900'">
              Due
            </button> -->
          </div>
          <div
            v-if="userRoles.isLudusAdmin || userRoles.isLudusManager"
            class="flex items-center justify-start xl:justify-center gap-2 fugaz w-full xl:w-max pt-3 xl:pt-0"
          >
            <button
              @click="changeDatasetsType('totals')"
              :disabled="selectedPlatforms.length == 0"
              class="px-1 uppercase transition-all duration-100 disabled:cursor-not-allowed"
              :class="[
                datasetsType === 'totals' ? 'text-orange-600' : 'text-white',
                selectedPlatforms.length !== 0
                  ? 'xl:hover:text-orange-600'
                  : 'opacity-40',
              ]"
            >
              {{ $t("sessions.platforms") }}
            </button>
            <button
              @click="changeDatasetsType('games')"
              class="px-1 uppercase xl:hover:text-orange-600 transition-all duration-100"
              :class="
                datasetsType === 'games' ? 'text-orange-600' : 'text-white'
              "
            >
              {{ $t("sessions.games") }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="totals.length > 0"
        class="flex items-center flex-wrap gap-2.5 bg-zinc-900 rounded-md text-white px-3 py-2"
      >
        <div
          v-for="(game, index) in totals.slice(0, 4)"
          class="font-semibold flex flex-col items-center bg-zinc-800 rounded-md px-2 py-1"
        >
          <span class="fugaz uppercase">{{ game.name }}</span>
          <span
            v-if="
              selectedValuesType !== 'rounds' &&
              selectedValuesType !== 'sessionsTotals' &&
              selectedValuesType !== 'usersCount'
            "
            :style="`color: ${game.color};`"
          >
            {{ getCurrencyValue(game.total) }}
          </span>
          <span v-else :style="`color: ${game.color};`">{{
            game.total.y ? game.total.y : game.total
          }}</span>
        </div>
        <button
          v-if="totals.length > 4"
          @click.stop="openMoreGames(true)"
          class="font-semibold flex flex-col items-center justify-center bg-zinc-800 rounded-md px-2 py-2.5 fugaz uppercase relative transition-all duration-150"
          :class="
            moreGames ? 'text-orange-600' : 'text-white hover:text-orange-600'
          "
        >
          + {{ totals.length - 4 }}
        </button>
        <div v-if="moreGames" class="relative">
          <div
            class="absolute -top-7 left-0 flex flex-col items-center gap-2 px-1 py-2 bg-zinc-900 rounded-md max-h-[300px] overflow-y-auto shadow-md border border-zinc-700"
          >
            <div
              v-for="(game, index) in totals.slice(4)"
              class="font-semibold flex flex-col items-center justify-center bg-zinc-800 rounded-md px-2 py-1 w-max"
            >
              <span class="fugaz uppercase text-center">{{ game.name }}</span>
              <span
                v-if="
                  selectedValuesType !== 'rounds' &&
                  selectedValuesType !== 'sessionsTotals' &&
                  selectedValuesType !== 'usersCount'
                "
                :style="`color: ${game.color};`"
              >
                {{ getCurrencyValue(game.total) }}
              </span>
              <span v-else :style="`color: ${game.color};`">{{
                game.total
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full h-[400px] md:h-full flex justify-between gap-3 bg-zinc-900 p-2 rounded-md"
      >
        <div class="w-full h-full flex flex-col text-white">
          <div class="flex justify-between gap-6">
            <div class="flex gap-1.5">
              <button
                @click="changeChartType('lines')"
                class="py-1 px-2 rounded-sm"
                :class="[
                  chartType == 'lines'
                    ? 'bg-zinc-600'
                    : 'bg-zinc-700 text-zinc-300',
                  chartTypesAllowed.includes('lines')
                    ? 'xl:hover:bg-opacity-60'
                    : 'opacity-50 ',
                ]"
              >
                <font-awesome-icon :icon="['fas', 'chart-line']" />
              </button>
              <button
                @click="changeChartType('bars')"
                class="py-1 px-2 rounded-sm"
                :class="
                  chartType == 'bars'
                    ? 'bg-zinc-600'
                    : 'bg-zinc-700 xl:hover:bg-opacity-60 text-zinc-300'
                "
              >
                <font-awesome-icon :icon="['fas', 'chart-column']" />
              </button>
              <!-- <button
                @click="changeChartType('barsHorizontal')"
                class="py-1 px-2 rounded-sm"
                :class="
                  chartType == 'barsHorizontal'
                    ? 'bg-zinc-600'
                    : 'bg-zinc-700 xl:hover:bg-opacity-60 text-zinc-300'
                "
              >
                <font-awesome-icon :icon="['fas', 'chart-bar']" />
              </button> -->
            </div>
            <!-- <div class="flex items-center gap-2">
              <button v-if="linesChartData.datasets.length > 0" @click="exportToExcel" class="py-1 px-2 rounded-sm bg-zinc-700 xl:hover:bg-opacity-60">
                <font-awesome-icon :icon="['fas', 'file-csv']" />   
              </button>
            </div> -->
          </div>
          <div
            class="w-full h-full max-h-full pt-2 flex items-center justify-center"
            :key="chartKey"
          >
            <Line
              v-if="!loadingChart && chartType == 'lines'"
              :data="linesChartData"
              :options="linesChartOptions"
            />
            <Bar
              v-if="!loadingChart && chartType == 'bars'"
              :data="linesChartData"
              :options="linesChartOptions"
            />
            <!-- <Bar
              v-if="
                !loadingChart &&
                chartType == 'barsHorizontal' &&
                horizontalBarsChartData
              "
              :data="horizontalBarsChartData"
              :options="horizontalBarOptions"
            /> -->
            <div v-if="loadingChart" class="flex items-center gap-4">
              <img
                src="../../assets/hub-full-logo.png"
                alt="gai hub logo"
                class="h-22 w-auto animate-pulse"
              />
              <!-- <img src="../../assets/loading2.png" alt="loading icon"
                class="text-zinc-400 h-[50px] animate-spin mb-1.5"
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import { useDateFormatter } from "@/helpers/dateFormatter.js";
import request from "@/services/axios";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { Pie, Line, Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement
);
export default {
  data() {
    return {
      ready: false,
      loading: false,
      loadingChart: false,
      allSessions: [],
      totals: [],
      selectedPlatforms: [],
      selectedGames: [],
      chartType: "lines",
      chartTypesAllowed: ["lines", "bars", "barsHorizontal"],
      linesFormat: "hour",
      tempLinesFormat: "hour",
      linesFormatsAllowed: ["hour"],
      sessionsType: "real",
      hideRealWins: false,
      chartLabels: [],
      selectedValuesType: "bet",
      linesChartData: {
        labels: [],
        datasets: [],
      },
      // horizontalBarsChartData: null,
      datasets: null,
      totals: [],
      moreGames: false,
      datasetsType: "games",
      dateKey: 0,
      isOpen: null,
    };
  },
  props: {
    allGames: Array,
    allPlatforms: Array,
    userPlatform: Object | null,
    chartKey: Number,
  },
  components: {
    DatePicker,
    Pie,
    Line,
    Bar,
    Multiselect,
  },
  async mounted() {
    // this.emitter.on('changingChartsToTotals', this.saveFilters)

    // await this.getAllPlatforms();
    // await this.getAllSessions();
    // await this.getTotals(true)
    // if(this.savedFilters !== null) {
    //   this.updateFilters()
    //   // this.getTotals(true)
    // }
    // else {
    // this.dateRange.start = this.getTodayStart();
    // this.dateRange.end = this.getTodayEnd();
    // this.dateKey++;
    // }
    this.$nextTick(() => {
      this.ready = true;
    });
  },
  watch: {
    // 'dateRange.start': {
    //   handler() {
    //     this.checkDatesInterval();
    //   }
    // }
  },
  computed: {
    dateRange() {
      return this.$store.state.filters.date;
    },
    allGamesSelected() {
      return this.selectedGames.length === this.allGames.length;
    },
    allPlatformsSelected() {
      return this.selectedPlatforms.length === this.allPlatforms.length;
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    savedFilters() {
      return this.$store.getters["charts/savedFilters"];
    },
    // chartTypeIndex() {
    //   switch (this.chartType) {
    //     case "barsHorizontal":
    //       if (!this.horizontalBarsChartData) {
    //         this.horizontalBarsChartData = this.swapXY(this.linesChartData);
    //       }

    //       return "y";
    //     default:
    //       return "x";
    //   }
    // },
    // horizontalBarOptions() {
    //   return {
    //     indexAxis: "y",
    //     // Elements options apply to all of the options unless overridden in a dataset
    //     // In this case, we are setting the border of each horizontal bar to be 2px wide
    //     elements: {
    //       bar: {
    //         borderWidth: 2,
    //       },
    //     },
    //     responsive: true,
    //     interaction: {
    //       mode: "index",
    //       intersect: true,
    //     },
    //     plugins: {
    //       legend: {
    //         position: "right",
    //       },
    //     },
    //     scales: {
    //       y: {
    //         grid: {
    //           color: "rgb(82 82 91)",
    //           borderColor: "green",
    //         },
    //       },
    //       x: {
    //         grid: {
    //           color: "rgb(82 82 91)",
    //           borderColor: "green",
    //         },
    //       },
    //     },
    //   };
    // },
    linesChartOptions() {
      return {
        responsive: true,
        interaction: {
          mode: "nearest",
          axis: "x",
          intersect: false,
        },
        plugins: {
          legend: {
            labels: {
              color: "white",
            },
          },

          tooltip: {
            callbacks: {
              itemSort: (a, b) => {
                return b.data - a.data;
              },
              label: (context) => {
                const value = context.dataset.data[
                  context.dataIndex
                ]?.hasOwnProperty("y")
                  ? context.dataset.data[context.dataIndex]?.y
                  : context.dataset.data[context.dataIndex];
                const datasetName = context.dataset.label;

                let label = "";
                if (
                  this.selectedValuesType !== "rounds" &&
                  this.selectedValuesType !== "sessionsTotals" &&
                  this.selectedValuesType !== "usersCount"
                ) {
                  label = this.getCurrencyValue(value);
                } else {
                  // Default number format for other datasets
                  label = value;
                }
                return `${datasetName}: ${label}`;
              },
            },
          },
        },
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            ticks: {
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 15,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
          x: {
            ticks: {
              color: "white", // Change scale label (number) color here
              font: {
                family: "Fugaz One, cursive", // Change font-family here
                weight: "medium",
                size: 12, // Adjust font size as needed
              },
              autoSkip: true,
              maxTicksLimit: 15,
            },
            grid: {
              color: "rgb(82 82 91)",
              borderColor: "green",
            },
          },
        },
        maintainAspectRatio: false,
      };
    },
    lastJob() {
      return this.$store.getters["jobs/lastJob"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    userRoles() {
      return this.user.localRoles;
    },
    storeSession() {
      return this.$store.getters["sessions/getSession"];
    },
  },
  methods: {
    // swapXY(dataObject) {
    //   // Iterate through each dataset
    //   dataObject.datasets = dataObject.datasets.map((dataset) => {
    //     // Swap x and y for each data point
    //     const updatedData = dataset.data.map(({ x, y }) => ({
    //       x: y,
    //       y: x,
    //     }));
    //     // Return the updated dataset
    //     return {
    //       ...dataset,
    //       data: updatedData,
    //     };
    //   });

    //   return dataObject;
    // },
    changeChartType(value) {
      if (!this.chartTypesAllowed.includes(value)) {
        this.$toast.open({
          message:
            "Chart type not allowed, increase date range or change date time group.",
          type: "error",
          duration: 5000,
          position: "bottom-right",
        });
      } else {
        this.chartType = value;
        // TODO fix this > ideally this would follow the reactive(computed) variable but that is not the case
        // if (this.chartType == "barsHorizontal") {
        //   this.chartTypeIndex = "y";
        //   // this.linesChartData.datasets.map( d => {
        //   //   d.indexAxis = "y";
        //   // });
        // } else {
        //   this.chartTypeIndex = "x";
        //   // this.linesChartData.datasets.map( d => {
        //   //   d.indexAxis = "x";
        //   // });
        // }
      }
    },
    changeIsOpen() {
      if (this.isOpen === null) {
        this.isOpen = true;
        return;
      }
      if (this.isOpen === true) {
        this.isOpen = false;
        return;
      }
      if (this.isOpen === false) {
        this.isOpen = null;
        return;
      }
    },
    changeDatasetsType(value) {
      this.datasetsType = value;
      if (
        this.linesChartData.datasets.length > 0 &&
        this.selectedGames.length > 0
      ) {
        this.getTotals();
      }
    },
    openMoreGames(value) {
      this.moreGames = value;
    },
    selectAllGames() {
      if (this.selectedGames.length !== this.allGames.length) {
        this.selectedGames = this.allGames.map((game) => game.id);
      } else {
        this.selectedGames = [];
      }
    },
    selectAllPlatforms() {
      if (this.selectedPlatforms.length !== this.allPlatforms.length) {
        this.selectedPlatforms = this.allPlatforms.map((game) => game.id);
        if (this.datasets === null) {
          this.datasetsType = "totals";
        }
      } else {
        this.selectedPlatforms = [];
        this.datasetsType = "games";
      }
    },
    saveFilters() {
      // console.log('specific saving filters')
      let submitted = false;
      if (this.savedFilters?.submitted || this.datasets !== null)
        submitted = true;
      this.$store.dispatch("charts/setSavedFilters", {
        dateRange: this.dateRange,
        selectedGames: this.selectedGames,
        tempLinesFormat: this.tempLinesFormat,
        sessionsType: this.sessionsType,
        submitted,
      });
    },
    updateFilters() {
      this.dateRange = this.savedFilters.dateRange;
      this.selectedGames = this.savedFilters.selectedGames;
      this.sessionsType = this.savedFilters.sessionsType;
      this.tempLinesFormat = this.savedFilters.tempLinesFormat;
    },
    checkDatesInterval() {
      const date1 = this.dateRange.start;
      const date2 = this.dateRange.end;
      // Check if the dates are on the same day
      const isSameDay =
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();

      const isSameMonth =
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear();

      if (this.tempLinesFormat === "hour") {
        this.chartTypesAllowed = ["lines", "bars", "barsHorizontal"];
      }
      if (
        (this.tempLinesFormat === "day" && isSameDay) ||
        (this.tempLinesFormat === "month" && isSameMonth)
      ) {
        this.chartTypesAllowed = ["bars", "barsHorizontal"];
        if (this.chartType === "lines") {
          this.chartType = "bars";
        }
      } else {
        this.chartTypesAllowed = ["lines", "bars", "barsHorizontal"];
      }
    },
    changeSessionsType(type = null) {
      if (type !== null) {
        this.sessionsType = type;
        return;
      }
      if (this.sessionsType === "fun") {
        this.sessionsType = "real";
        return;
      }
      if (this.sessionsType === "real") {
        this.sessionsType = "all";
        return;
      }
      if (this.sessionsType === "all") {
        this.sessionsType = "fun";
        return;
      }
    },
    getRandomColor(minBrightness = 50, minSaturation = 50) {
      var getRandomValue = (min, max) => {
        return Math.random() * (max - min) + min;
      };

      var hue = getRandomValue(0, 360);
      var saturation = getRandomValue(minSaturation, 100);
      var lightness = getRandomValue(minBrightness, 100);

      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },
    changeChartsValuesType(type) {
      if (type !== this.selectedValuesType) {
        this.selectedValuesType = type;
        if (this.datasets !== null) {
          this.loadingChart = true;
          this.$nextTick(() => {
            const datasets = this.datasets[this.datasetsType][type];
            this.linesChartData.datasets = this.linesChartData.datasets.map(
              (dataset) => {
                const newItem = datasets.find(
                  (game) => game.label === dataset.label
                );
                if (newItem) {
                  const newObj = { ...dataset, data: newItem.data };
                  if (newItem.totalData) newObj.totalData = newItem.totalData;
                  return newObj;
                }

                return dataset;
              }
            );
            this.setItemsTotals(this.linesChartData.datasets, type);
            this.loadingChart = false;
          });
        }
      }
    },
    getItemDataSum(data, type) {
      // TODO fix me oh ffs fix me; this value can be returned directly from the response instead of summing it all
      //console.log("o ganda getItemDataSum");
      //console.log(data);
      let sum = 0;
      for (let value of data) {
        if (value.hasOwnProperty("y")) {
          value = value.y;
        }
        sum += value;
      }
      return sum;
    },
    setItemsTotals(datasets, type) {
      // console.log(datasets)
      const totals = datasets.map((item) => {
        return {
          name: item.label,
          total:
            type === "usersCount" && item.totalData
              ? item.totalData
              : this.getItemDataSum(item.data, type),
          color: item.backgroundColor,
        };
      });
      totals.sort((a, b) => b.total - a.total);
      this.totals = totals;
    },
    areDatesOnSameDay(dateArray) {
      const firstDateDay = dateArray[0].split(" ")[0];
      console.log("- areDatesOnSameDay -");

      const isOnSameDay = dateArray.every((date) => {
        console.log("firstDateDay", firstDateDay);
        console.log('date.split(" ")[0]', date.split(" ")[0]);
        return date.split(" ")[0] === firstDateDay;
      });
      console.log("isOnSameDay", isOnSameDay);

      return isOnSameDay;
    },
    areDatesOnSameYear(dateArray) {
      return dateArray.every(
        (date) =>
          new Date(date).getFullYear() === new Date(dateArray[0]).getFullYear()
      );
    },
    areMonthsOnSameYear(dateArray) {
      return dateArray.every((date) => {
        const date1String = `${date}-01`;
        const date2String = `${dateArray[0]}-01`;
        return (
          new Date(date1String).getFullYear() ===
          new Date(date2String).getFullYear()
        );
      });
    },
    formatFunction(label, labels) {
      if (this.linesFormat == "hour") {
        console.log("format function by hour");
        const labelSplitted = label.split(" ");
        console.log(
          "!!!!!!!!!!!!!!!!!!!!!!!! labelSplitted[0]",
          labelSplitted[0]
        );
        const labelDate = new Date(labelSplitted[0]);
        const hour = parseInt(labelSplitted[1]);
        labelDate.setHours(hour);

        const options = { hour: "numeric", minute: "numeric" };

        if (!this.areDatesOnSameDay(labels)) {
          options.day = "numeric";
          options.month = "short";
        }

        return {
          date: labelDate,
          options,
        };
      }

      if (this.linesFormat == "day") {
        console.log("format function by day");
        const labelDate = new Date(label);
        const options = { day: "numeric", month: "short" };
        if (!this.areDatesOnSameYear(labels)) {
          options.year = "numeric";
        }
        return {
          date: labelDate,
          options,
        };
      }
      if (this.linesFormat == "month") {
        console.log("format function by month");
        const labelString = `${label}-01`;
        const labelDate = new Date(labelString);
        const options = { month: "long" };
        if (!this.areMonthsOnSameYear(labels)) {
          options.year = "numeric";
        }
        return {
          date: labelDate,
          options,
        };
      }
    },
    singlePlatformGamesCharts(response) {
      // console.log(response.labels)

      console.log("singlePlatformGamesCharts", response);
      const labels = response.labels.map((label) =>
        this.formatFunction(label, response.labels)
      );

      labels.sort((a, b) => new Date(a.date) - new Date(b.date));

      this.linesChartData.labels = labels.map((label) => {
        console.log("singlePlatformGamesCharts - label", label);
        if (label.options.day || label.options.month) {
          return label.date.toLocaleDateString("en-GB", label.options);
        } else {
          return label.date.toLocaleTimeString("en-GB", label.options);
        }
      });

      const datasets = response.datasets;

      this.datasets = datasets;
      const itemDatasets =
        response.datasets[this.datasetsType][this.selectedValuesType];

      // this.linesChartData.datasets = datasets
      const finalDatasets = itemDatasets.map((dataset) => {
        const randomColor = this.getRandomColor();
        return {
          ...dataset,
          borderColor: randomColor,
          backgroundColor: randomColor,
        };
      });
      this.linesChartData.datasets = finalDatasets;
      this.setItemsTotals(finalDatasets, this.selectedValuesType);
    },

    mergePlatformsData(response) {
      console.log("oioioioioi");
      let mergedDatasets = {};

      response.forEach((res) => {
        const platformName = res.platform.name;

        Object.entries(res.datasets[this.datasetsType]).forEach(
          ([itemType, itemArray]) => {
            // If itemType does not exist in mergedDatasets, create an empty array
            if (!mergedDatasets[itemType]) {
              mergedDatasets[itemType] = [];
            }

            // Iterate through each game object
            itemArray.forEach((item) => {
              // Append platform information to the game label
              if (this.datasetsType == "games") {
                if (this.selectedPlatforms.length > 1) {
                  item.label += ` - ${platformName}`;
                }
              } else {
                item.label = platformName;
              }
              // Add the game object to the merged dataset

              // item.indexAxis = this.chartTypeIndex;

              item.data.forEach((value, index) => {
                let label = this.formatFunction(res.labels[index], res.labels);
                let labelFormatted = label.date.toLocaleDateString(
                  "en-GB",
                  label.options
                );
                item.data[index] = { x: labelFormatted, y: value };
                if (item.data[index].hasOwnProperty("totals")) {
                  delete item.data[index].totals;
                }
              });

              mergedDatasets[itemType].push(item);
            });
          }
        );
      });

      // Create a new object to hold the merged datasets
      let mergedResponse = {
        [this.datasetsType]: mergedDatasets,
      };

      return mergedResponse; // Return the merged response
    },
    multiplePlatformsGamesCharts(response) {
      console.log("multiplePlatformsGamesCharts");
      const allLabels = response.map((obj) => obj.labels).flat();
      // .reduce((acc, curr) => acc.concat(curr), []);
      const uniqueLabels = [...new Set(allLabels)];
      const labels = uniqueLabels.map((label) =>
        this.formatFunction(label, uniqueLabels)
      );
      labels.sort((a, b) => new Date(a.date) - new Date(b.date));

      this.linesChartData.labels = labels.map((label) => {
        console.log("multiplePlatformsGamesCharts - label", label);

        if (label.options.day || label.options.month) {
          return label.date.toLocaleDateString("en-GB", label.options);
        } else {
          return label.date.toLocaleTimeString("en-GB", label.options);
        }
      });

      const datasets = this.mergePlatformsData(response);

      this.datasets = datasets;
      const itemDatasets = datasets[this.datasetsType][this.selectedValuesType];

      // this.linesChartData.datasets = datasets
      const finalDatasets = itemDatasets.map((dataset) => {
        const randomColor = this.getRandomColor();
        return {
          ...dataset,
          borderColor: randomColor,
          backgroundColor: randomColor,
        };
      });
      this.linesChartData.datasets = finalDatasets;
      this.setItemsTotals(finalDatasets, this.selectedValuesType);
    },
    async getTotals(fromSubmit) {
      this.checkDatesInterval();
      if (fromSubmit) this.loading = true;
      if (this.sessionsType === "fun") this.hideRealWins = true;
      this.linesFormat = this.tempLinesFormat;
      this.loadingChart = true;
      try {
        const from = new Date(this.dateRange.start);
        const to = new Date(this.dateRange.end);
        const gameIds = this.selectedGames.length > 0 ? this.selectedGames : [];

        let platformIds = null;
        if (this.selectedPlatforms.length) platformIds = this.selectedPlatforms;
        if (this.userPlatform) platformIds = [this.userPlatform.id];
        let groupDate = "";
        if (this.linesFormat == "hour") groupDate = "%Y-%m-%d %H";
        if (this.linesFormat == "day") groupDate = "%Y-%m-%d";
        if (this.linesFormat == "month") groupDate = "%Y-%m";

        let sessionTypeId = null;
        if (this.sessionsType == "real") sessionTypeId = 1;
        if (this.sessionsType == "fun") sessionTypeId = 4;

        if (this.selectedPlatforms.length == 0) this.datasetsType = "games";

        const requestObject = {
          from: useDateFormatter(from),
          to: useDateFormatter(to),
          games: gameIds,
          platforms: platformIds,
          stats: "bet,win,grosswin,netwin,due,sessionsTotals,usersCount,rounds",
          datasetTotals: 1,
          datasetGames: this.datasetsType == "games" ? 1 : 0,
          datasetPlatforms: platformIds ? 1 : 0,
          key: groupDate,
          sessionTypeId,
        };

        if (this.isOpen !== null) {
          requestObject.isOpen = this.isOpen;
        }

        const a = btoa(JSON.stringify(requestObject));
        const response = (await request(`/bookkeeping/stats?a=${a}`)).data;

        if (this.datasetsType === "games") {
          if (platformIds == null) {
            this.singlePlatformGamesCharts(response);
          } else {
            this.multiplePlatformsGamesCharts(response);
          }
        } else {
          this.multiplePlatformsGamesCharts(response);
        }

        // New else condition handling
        if (this.linesChartData.datasets.length === 0) {
          this.$toast.warning("No results found with these filters");
        } else {
          this.loadingChart = false; // Ensure loading is set to false if data is found
        }

        if (this.sessionsType !== "fun") this.hideRealWins = false;
      } catch (e) {
        console.log("Error fetching totals:", e);
      } finally {
        if (this.loading) this.loading = false; // Ensure loading is reset
      }
    },

    // async getTotals(fromSubmit) {
    //   this.checkDatesInterval();
    //   if (fromSubmit) this.loading = true;
    //   if (this.sessionsType === "fun") this.hideRealWins = true;
    //   this.linesFormat = this.tempLinesFormat;
    //   this.loadingChart = true;
    //   try {
    //     const from = new Date(this.dateRange.start);
    //     const to = new Date(this.dateRange.end);
    //     const gameIds = this.selectedGames.length > 0 ? this.selectedGames : [];

    //     let platformIds = null;
    //     if (this.selectedPlatforms.length) platformIds = this.selectedPlatforms;
    //     if (this.userPlatform) platformIds = [this.userPlatform.id];
    //     let groupDate = "";
    //     if (this.linesFormat == "hour") groupDate = "%Y-%m-%d %H";
    //     if (this.linesFormat == "day") groupDate = "%Y-%m-%d";
    //     if (this.linesFormat == "month") groupDate = "%Y-%m";

    //     let sessionTypeId = null;
    //     if (this.sessionsType == "real") sessionTypeId = 1;
    //     if (this.sessionsType == "fun") sessionTypeId = 4;

    //     if (this.selectedPlatforms.length == 0) this.datasetsType = "games";

    //     const requestObject = {
    //       from: useDateFormatter(from),
    //       to: useDateFormatter(to),
    //       games: gameIds,
    //       platforms: platformIds,
    //       stats: "bet,win,grosswin,netwin,due,sessionsTotals,usersCount,rounds",
    //       datasetTotals: 1,
    //       datasetGames: this.datasetsType == "games" ? 1 : 0,
    //       datasetPlatforms: platformIds ? 1 : 0,
    //       key: groupDate,
    //       sessionTypeId,
    //     };
    //     if (this.isOpen !== null) {
    //       requestObject.isOpen = this.isOpen;
    //     }
    //     const a = btoa(JSON.stringify(requestObject));
    //     const response = (await request(`/bookkeeping/stats?a=${a}`)).data;
    //     if (this.datasetsType === "games") {
    //       if (platformIds == null) {
    //         this.singlePlatformGamesCharts(response);
    //       } else {
    //         this.multiplePlatformsGamesCharts(response);
    //       }
    //     } else {
    //       this.multiplePlatformsGamesCharts(response);
    //     }

    //     if (this.sessionsType !== "fun") this.hideRealWins = false;
    //     if (this.linesChartData.datasets.length === 0) {
    //       this.$toast.warning("No results found with these filters");
    //     }
    //   } catch (e) {
    //     console.log(e);
    //     this.serverError = true;
    //   }
    //   if (fromSubmit) this.loading = false;
    //   this.loadingChart = false;
    //   this.saveFilters();
    // },
  },
};
</script>

<style scoped>
</style>