<template>
  <div
    class="w-full flex flex-col items-start bg-zinc-950 relative gap-4 z-[20] pb-[65px] xl:pb-0 py-4 px-4 md:px-7"
  >
    <h1 class="text-3xl xl:text-4xl text-center fugaz uppercase text-white">
      Settings
    </h1>
    <div class="w-full flex flex-col mt-4">
      <h4 class="text-white fugaz uppercase">Language</h4>
      <div class="flex items-start gap-2 pl-1 pt-2">
        <button
          @click="changeLanguage(language)"
          v-for="language of languages"
          class="transition-all duration-150 relative flex flex-col items-center"
          :class="
            appLanguage.short == language.short ? 'opacity-100' : 'opacity-40'
          "
        >
          <img
            :src="language.image"
            alt="language flag"
            class="w-11 h-8 object-cover"
          />
          <span
            v-if="language.beta"
            class="font-semibold text-green-500 text-xs"
            >Beta</span
          >
        </button>
      </div>
    </div>
    <div
      v-if="ready && user.localRoles.isLudusAdmin"
      class="flex flex-col lg:flex-row items-start gap-3 w-full"
    >
      <div class="text-white w-full space-y-2">
        <h5 class="fugaz uppercase pl-1">Generate bookkeeping email</h5>
        <div
          v-if="ready && user.localRoles.isLudusAdmin"
          class="w-[100%] flex items-center gap-1.5"
          :class="
            appLanguage.long == 'en-GB' ? 'min-w-[330px]' : 'min-w-[440px]'
          "
        >
          <Multiselect
            mode="single"
            v-model="selectedPlatform"
            :options="allPlatforms"
            label="name"
            value-prop="id"
            :placeholder="'Select a Platform (Empty = All)'"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :multiple-label="(values) => `${values.length} Platforms selected`"
          />
          <!-- <button class="px-3 py-1 h-max rounded-sm bg-zinc-700 fugaz uppercase xl:hover:text-orange-600">ALL</button> -->
        </div>
        <div class="w-[100%] flex items-center gap-1.5">
          <Multiselect
            ref="multiselectUsersToSendReport"
            mode="multiple"
            v-model="usersToSendReport"
            :options="users"
            label="username"
            value-prop="email"
            placeholder="Select Users to Send Report"
            :hide-selected="false"
            :caret="false"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            @create="validateUser"
            :multiple-label="(values) => `${values.length} Users selected`"
          />
          <!-- :add-option-on="['enter', 'space', 'tab', ';', ',']" -->
          <!-- :create="true"
            :append-new-option="true" -->
          <!-- rules="required|email"
            @select="userSelectedForReportsHandler" -->
          <button
            @click="selectAllUsers"
            class="px-3 pb-1 pt-1.5 h-max rounded-sm bg-zinc-700 fugaz uppercase xl:hover:text-orange-600 xl:hover:bg-zinc-800"
            :class="
              usersToSendReport.length === users.length && 'text-orange-600'
            "
          >
            ALL
          </button>
        </div>
        <div class="flex flex-wrap justify-center gap-1.5 max-w-[100%] pl-1">
          <div
            v-for="user in usersToSendReport"
            class="px-2 rounded-md bg-zinc-700 cursor-default"
          >
            {{ !!user ? user : "N/A" }}
          </div>
        </div>
        <div class="flex flex-col items-start z-[30] p2 mt-4">
          <div class="flex items-center gap-x-2 mb-2 mt-2">
            <p class="text-zinc-300 whitespace-nowrap mr-2.5 text-sm">
              {{ $t("sessions.dateRange") }}
            </p>
            <label
              class="px-3 pt-1 pb-0.5 rounded-[3px] border bg-zinc-900 text-sm fugaz uppercase cursor-pointer"
              :class="
                type === 'daily'
                  ? 'border-orange-600'
                  : 'border-zinc-800 hover:border-zinc-400'
              "
            >
              <!-- class="truncate text-[14px] fugaz uppercase cursor-pointer mr-3"
              :class="type === 'daily' ? 'text-orange-600' : 'text-white'" -->
              <input type="radio" v-model="type" value="daily" class="hidden" />
              Daily
            </label>
            <label
              class="px-3 pt-1 pb-0.5 rounded-[3px] border bg-zinc-900 text-sm fugaz uppercase cursor-pointer"
              :class="
                type === 'custom'
                  ? 'border-orange-600'
                  : 'border-zinc-800 hover:border-zinc-400'
              "
            >
              <!-- class="truncate text-[14px] fugaz uppercase cursor-pointer mr-3"
              :class="type === 'custom' ? 'text-orange-600' : 'text-white'" -->
              <input
                type="radio"
                v-model="type"
                value="custom"
                class="hidden"
              />
              Custom
            </label>

            <label
              class="px-3 pt-1 pb-0.5 rounded-[3px] border bg-zinc-900 text-sm fugaz uppercase cursor-pointer"
              :class="
                type === 'monthly'
                  ? 'border-orange-600'
                  : 'border-zinc-800 hover:border-zinc-400'
              "
            >
              <!-- class="truncate text-[14px] fugaz uppercase cursor-pointer"
              :class="type === 'monthly' ? 'text-orange-600' : 'text-white'" -->
              <input
                type="radio"
                v-model="type"
                value="monthly"
                class="hidden"
              />
              Monthly
            </label>
          </div>
          <!-- TODO ANA - LOCALE DEFAULT IT -->
          <div class="w-[22rem]">
            <DatePicker :type="type" />
          </div>
        </div>
        <div class="flex justify-center mt-1">
          <button
            v-if="!loadingReport"
            @click="sendReport"
            :disabled="usersToSendReport.length == 0"
            class="px-5 pt-0.5 rounded-sm w-max bg-zinc-700 fugaz uppercase disabled:opacity-50"
            :class="usersToSendReport.length > 0 && 'xl:hover:text-orange-600'"
          >
            Send
          </button>
          <img
            v-else
            src="../assets/loading2.png"
            alt="loading icon"
            class="w-auto h-6 text-zinc-500 object-cover animate-spin"
          />
        </div>
      </div>

      <div class="text-white flex flex-col gap-2 w-full">
        <h5 class="fugaz uppercase pl-1 text-center">Send user guide email</h5>
        <!-- <input 
          v-model="pdfUrl" 
          type="text" 
          placeholder="Tutorial PDF file url" 
          class="w-full pl-4 py-[8px] rounded-[4px] bg-zinc-900 outline-none"
          :class="pdfUrlError && 'border border-red-500'"
        /> -->
        <input
          v-model="newTutorialEmail"
          type="email"
          placeholder="Email(s) to send"
          class="w-full pl-4 py-[8px] rounded-[4px] bg-zinc-900 outline-none"
          :class="tutorialEmailsError && 'border border-red-500'"
          @keyup.enter="addTutorialUser"
        />
        <div
          v-if="!loadingTutorial"
          class="flex flex-wrap justify-center gap-1.5 max-w-[100%]"
        >
          <div
            v-for="email in tutorialEmails"
            class="px-2 rounded-md bg-zinc-700"
          >
            {{ email }}
          </div>
        </div>
        <div class="flex justify-center">
          <button
            v-if="!loadingTutorial"
            @click="sendTutorialEmails"
            type="button"
            class="px-5 pt-0.5 rounded-sm w-max bg-zinc-800 fugaz uppercase disabled:opacity-50 xl:hover:text-orange-600"
          >
            Send
          </button>
          <img
            v-else
            src="../assets/loading2.png"
            alt="loading icon"
            class="w-auto h-6 text-zinc-500 object-cover animate-spin"
          />
        </div>
      </div>
    </div>
    <div
      v-if="ready && user.localRoles.isLudusAdmin"
      class="flex flex-col items-start w-full text-white"
    >
      <h2 class="fugaz uppercase">Jobs</h2>
      <div class="flex flex-col sm:flex-row w-full gap-2.5">
        <div
          class="border border-zinc-700 rounded-md min-h-[22rem] max-h-[410px] w-full flex flex-col"
        >
          <h4
            class="fugaz uppercase text-center py-1.5 border-b border-zinc-700 rounded-t-md bg-zinc-950"
          >
            Active
          </h4>
          <div
            v-if="jobs.active.length > 0"
            class="flex flex-col gap-2 h-full overflow-y-auto overflow-x-hidden px-2 py-2 rounded-b-md"
          >
            <div
              v-for="job in jobs.active"
              class="bg-zinc-900 rounded-sm border border-zinc-700 w-full flex flex-col text-center"
            >
              <div
                @click="expandJob(job.id, 'active', true)"
                class="flex justify-between transition-all duration-150 bg-zinc-900 relative"
                :class="!job.expanded && 'hover:brightness-125 cursor-pointer'"
              >
                <div
                  v-if="!job.expanded && job.progress.progress?.percentage"
                  class="absolute top-0 left-0 h-full z-[1] transition-all duration-300"
                  :class="[
                    'bg-green-700',
                    job.progress.progress.percentage > 95
                      ? 'rounded-sm'
                      : 'rounded-l-sm',
                  ]"
                  :style="`width: ${job.progress.progress.percentage}%;`"
                ></div>
                <div class="flex flex-col items-start px-1.5 py-0.5 z-[2]">
                  <span class="font-light text-sm">Name:</span>
                  <span class="text-start capitalize">{{
                    job.name.replaceAll("-", " ")
                  }}</span>
                </div>
                <div class="flex flex-col items-start px-1.5 py-0.5 z-[2]">
                  <span class="font-light text-sm">Added at:</span>
                  <span class="text-start">{{
                    clearDate(job.processedOn)
                  }}</span>
                </div>
              </div>
              <div v-if="job.expanded" class="flex flex-col w-full">
                <div class="flex justify-between border-t border-zinc-800">
                  <div
                    v-if="job.data.userId"
                    class="flex flex-col items-start px-1.5 py-0.5"
                  >
                    <span class="font-light text-sm">Created by:</span>
                    <span class="text-start capitalize">{{
                      usersFromDB.find(
                        (user) => user.keycloakUserId == job.data.userId
                      ).username
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="job.data.dateStart || job.data.dateEnd"
                  class="flex flex-wrap border-t border-zinc-800"
                >
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">From:</span>
                    <span class="text-start">{{
                      fullDate(job.data.dateStart)
                    }}</span>
                  </div>
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">To:</span>
                    <span class="text-start">{{
                      fullDate(job.data.dateEnd)
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="job.data.dateStart || job.data.dateEnd"
                  class="flex flex-col items-start px-1.5 py-0.5 border-t border-zinc-800"
                >
                  <span class="font-light text-sm">Platforms:</span>
                  <span
                    v-if="
                      job.data.platformIds === null ||
                      job.data.platformId === null
                    "
                    >All</span
                  >
                  <div v-else class="flex flex-wrap gap-1">
                    <div
                      v-for="plat in allPlatforms.filter((plat) =>
                        job.data.platformId
                          ? job.data.platformId == plat.id
                          : job.data.platformIds.includes(plat.id.toString())
                      )"
                      class="bg-zinc-800 px-1.5 py-px rounded-md"
                    >
                      {{ plat.name }}
                    </div>
                  </div>
                </div>
                <div
                  class="flex justify-between items-center w-full py-0.5 relative border-t border-zinc-800"
                >
                  <div
                    v-if="job.progress.progress?.percentage"
                    class="absolute top-0 left-0 h-full z-[1] transition-all duration-300"
                    :class="[
                      'bg-green-700',
                      job.progress.progress.percentage > 95
                        ? 'rounded-b-sm'
                        : 'rounded-bl-sm',
                    ]"
                    :style="`width: ${job.progress.progress.percentage}%;`"
                  ></div>
                  <div
                    v-if="job.progress.progress?.percentage"
                    class="flex flex-col items-start px-1.5 pt-0.5 z-[2]"
                  >
                    <span class="font-light text-sm">Progress:</span>
                    <span class="max-w-full truncate"
                      >{{ Math.floor(job.progress.progress.percentage) }}% -
                      {{ job.progress.progress.message }}</span
                    >
                  </div>
                  <div class="flex flex-col items-start px-1.5 pt-0.5 z-[2]">
                    <span class="font-light text-sm">Elapsed time:</span>
                    <span>{{ job.elapsedTime }}</span>
                  </div>
                </div>
                <button
                  @click="expandJob(job.id, 'active', false)"
                  class="w-full pt-px hover:bg-zinc-800 hover:text-orange-600 rounded-b-sm border-t border-zinc-800"
                >
                  <font-awesome-icon :icon="['fas', 'caret-up']" />
                </button>
              </div>
            </div>
          </div>
          <div v-else class="flex justify-center pt-1.5">Empty</div>
        </div>
        <div
          class="border border-zinc-700 rounded-md min-h-[22rem] max-h-[410px] w-full flex flex-col"
        >
          <h4
            class="fugaz uppercase text-center py-1.5 border-b border-zinc-700 rounded-t-md bg-zinc-950"
          >
            Waiting
          </h4>
          <div
            v-if="jobs.waiting.length > 0"
            class="flex flex-col gap-2 h-full overflow-y-auto overflow-x-hidden px-2 py-2 rounded-b-md"
          >
            <div
              v-for="job in jobs.waiting"
              class="bg-zinc-900 rounded-sm border border-zinc-700 w-full flex flex-col text-center capitalize relative"
            >
              <div
                @click="expandJob(job.id, 'waiting', true)"
                class="flex justify-between transition-all duration-150 bg-zinc-900 relative"
                :class="!job.expanded && 'hover:brightness-125 cursor-pointer'"
              >
                <div class="flex flex-col items-start px-1.5 py-0.5">
                  <span class="font-light text-sm">Name:</span>
                  <span class="text-start">{{
                    job.name.replaceAll("-", " ")
                  }}</span>
                </div>
                <div class="flex flex-col items-start px-1.5 py-0.5">
                  <span class="font-light text-sm">Added at:</span>
                  <span class="text-start">{{ clearDate(job.timestamp) }}</span>
                </div>
              </div>
              <div v-if="job.expanded" class="flex flex-col w-full">
                <div
                  v-if="job.data.userId"
                  class="flex justify-between border-t border-zinc-800"
                >
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">Created by:</span>
                    <span class="text-start">{{
                      usersFromDB.find(
                        (user) => user.keycloakUserId == job.data.userId
                      ).username
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="job.data.dateStart || job.data.dateEnd"
                  class="flex flex-wrap border-t border-zinc-800"
                >
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">From:</span>
                    <span class="text-start">{{
                      fullDate(job.data.dateStart)
                    }}</span>
                  </div>
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">To:</span>
                    <span class="text-start">{{
                      fullDate(job.data.dateEnd)
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="job.data.dateStart || job.data.dateEnd"
                  class="flex flex-col items-start px-1.5 py-0.5 border-t border-zinc-800"
                >
                  <span class="font-light text-sm">Platforms:</span>
                  <span
                    v-if="
                      job.data.platformIds === null ||
                      job.data.platformId === null
                    "
                    >All</span
                  >
                  <div v-else class="flex flex-wrap gap-1">
                    <div
                      v-for="plat in allPlatforms.filter((plat) =>
                        job.data.platformId
                          ? job.data.platformId == plat.id
                          : job.data.platformIds.includes(plat.id.toString())
                      )"
                      class="bg-zinc-800 px-1.5 py-px rounded-md"
                    >
                      {{ plat.name }}
                    </div>
                  </div>
                </div>
                <button
                  @click="expandJob(job.id, 'waiting', false)"
                  class="w-full pt-px hover:bg-zinc-800 hover:text-orange-600 rounded-b-sm border-t border-zinc-800"
                >
                  <font-awesome-icon :icon="['fas', 'caret-up']" />
                </button>
              </div>
              <button
                @click="clearWaitingJob(job.queueQualifiedName, job.id)"
                class="absolute bottom-0 right-1"
              >
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  class="h-3.5 pl-px text-white hover:text-red-500"
                />
              </button>
            </div>
          </div>
          <div v-else class="flex justify-center pt-1.5">Empty</div>
        </div>
        <div
          class="border border-zinc-700 rounded-md min-h-[22rem] max-h-[410px] w-full flex flex-col"
        >
          <h4
            class="fugaz uppercase text-center py-1.5 border-b border-zinc-700 rounded-t-md bg-zinc-950"
          >
            Completed
          </h4>
          <div
            v-if="jobs.completed.length > 0"
            class="flex flex-col gap-2 h-full overflow-y-auto overflow-x-hidden px-2 py-2 rounded-b-md"
          >
            <div
              v-for="job in jobs.completed"
              class="bg-zinc-900 rounded-sm border border-zinc-700 w-full flex flex-col text-center capitalize"
            >
              <div
                @click="expandJob(job.id, 'completed', true)"
                class="flex justify-between transition-all duration-150 bg-zinc-900"
                :class="!job.expanded && 'hover:brightness-125 cursor-pointer'"
              >
                <div class="flex flex-col items-start px-1.5 py-0.5">
                  <span class="font-light text-sm">Name:</span>
                  <span class="text-start">{{
                    job.name.replaceAll("-", " ")
                  }}</span>
                </div>
                <div class="flex flex-col items-start px-1.5 py-0.5">
                  <span class="font-light text-sm">At:</span>
                  <span class="text-start">{{
                    clearDate(job.finishedOn)
                  }}</span>
                </div>
              </div>
              <div v-if="job.expanded" class="flex flex-col">
                <div
                  v-if="job.data.userId"
                  class="flex justify-between border-t border-zinc-800"
                >
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">Created by:</span>
                    <span class="text-start">{{
                      usersFromDB.find(
                        (user) => user.keycloakUserId == job.data.userId
                      )?.username
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="job.data.dateStart || job.data.dateEnd"
                  class="flex flex-wrap border-t border-zinc-800"
                >
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">From:</span>
                    <span class="text-start">{{
                      fullDate(job.data.dateStart)
                    }}</span>
                  </div>
                  <div class="flex flex-col items-start px-1.5 py-0.5">
                    <span class="font-light text-sm">To:</span>
                    <span class="text-start">{{
                      fullDate(job.data.dateEnd)
                    }}</span>
                  </div>
                </div>
                <div
                  v-if="job.data.dateStart || job.data.dateEnd"
                  class="flex flex-col items-start px-1.5 py-0.5 border-t border-zinc-800"
                >
                  <span class="font-light text-sm">Platforms:</span>
                  <span
                    v-if="
                      job.data.platformIds === null ||
                      job.data.platformId === null
                    "
                    >All</span
                  >
                  <div v-else class="flex flex-wrap gap-1">
                    <div
                      v-for="plat in allPlatforms.filter((plat) =>
                        job.data.platformId
                          ? job.data.platformId == plat.id
                          : job.data.platformIds.includes(plat.id.toString())
                      )"
                      class="bg-zinc-800 px-1.5 py-px rounded-md"
                    >
                      {{ plat.name }}
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col items-start px-1.5 py-0.5 border-t border-zinc-800"
                >
                  <span class="font-light text-sm">Duration:</span>
                  <span class="text-start">{{
                    calculateTimeDifference(job.processedOn, job.finishedOn)
                  }}</span>
                </div>
                <button
                  @click="expandJob(job.id, 'completed', false)"
                  class="w-full pt-px hover:bg-zinc-800 hover:text-orange-600 rounded-b-sm border-t border-zinc-800"
                >
                  <font-awesome-icon :icon="['fas', 'caret-up']" />
                </button>
              </div>
            </div>
          </div>
          <div v-else class="flex justify-center pt-1.5">Empty</div>
        </div>
        <div
          class="border border-zinc-700 rounded-md min-h-[22rem] max-h-[410px] w-full flex flex-col"
        >
          <h4
            class="fugaz uppercase text-center py-1.5 border-b border-zinc-700 bg-zinc-950"
          >
            Delayed
          </h4>
          <div
            v-if="jobs.delayed.length > 0"
            class="flex flex-col gap-2 h-full overflow-y-auto overflow-x-hidden px-2 py-2 rounded-b-md"
          >
            <div
              v-for="job in jobs.delayed"
              class="bg-zinc-900 rounded-sm border border-zinc-700 w-full flex justify-between text-center capitalize cursor-default"
            >
              <div class="flex flex-col items-start px-1.5 py-0.5">
                <span class="font-light text-sm">Name:</span>
                <span class="text-start">{{
                  job.name.replaceAll("-", " ")
                }}</span>
              </div>
              <div class="flex flex-col items-start px-1.5 py-0.5">
                <span class="font-light text-sm text-left"
                  >Remaining time:</span
                >
                <span class="text-start">{{
                  timeLeft(job.timestamp, job.delay)
                }}</span>
              </div>
            </div>
          </div>
          <div v-else class="flex justify-center pt-1.5">Empty</div>
        </div>
      </div>
    </div>
    <div v-if="!ready" class="w-full flex justify-center pt-6">
      <img
        src="../assets/hub-full-logo.png"
        alt="gai hub logo"
        class="h-24 w-auto animate-pulse"
      />
    </div>
  </div>
</template>
<script>
import request from "@/services/axios";
import Multiselect from "@vueform/multiselect";
import DatePicker from "@/components/DatePicker";
import { useDateFormatter } from "@/helpers/dateFormatter.js";

export default {
  data() {
    return {
      ready: false,
      loadingReport: false,
      loadingTutorial: false,
      usersFromDB: [],
      usersToSendReport: [],
      newTutorialEmail: "",
      selectedPlatform: null,
      allPlatforms: [],
      pdfUrl: "",
      pdfUrlError: false,
      tutorialEmails: [],
      tutorialEmailsError: false,
      languages: [
        {
          short: "en",
          long: "en-GB",
          image: require("../assets/british-flag.png"),
        },
        {
          short: "it",
          long: "it-IT",
          image: require("../assets/italy-flag.png"),
          beta: true,
        },
      ],
      type: "daily",
      date: new Date(),
      jobs: {
        active: [],
        waiting: [],
        completed: [],
        delayed: [],
      },
      intervalId: null,
      intervalIdActive: null,
      loadingActiveJobs: false,
      loadingWaitingJobs: false,
      radius: 13, // Radius of the circle,
    };
  },
  components: {
    Multiselect,
    DatePicker,
  },
  async mounted() {
    if (this.user.localRoles.isLudusAdmin) {
      await this.getUsers();
      await this.getAllPlatforms();
    }
    await this.getJobs();
    this.ready = true;

    this.intervalId = setInterval(async () => {
      if (this.jobs.active.length > 0) {
        await this.getWaitingJobs();
      }
    }, 0.5 * 60 * 1000); // check for new waiting jobs
    this.intervalIdActive = setInterval(this.updateElapsedTime, 1000);

    this.socket.on("job-progress", async (response) => {
      if (this.jobs.active.length === 0 && !this.loadingActiveJobs) {
        // console.log('getting active jobs')
        await this.getActiveJobs();
        this.updateElapsedTime();
      }
      const message = response.message;
      // console.log(message)
      const index = this.jobs.active.findIndex(
        (job) => job.id == message.jobId
      );
      if (index !== -1) {
        this.jobs.active[index].progress.progress = message.progress;
      }
    });
    this.socket.on("job-completed", async (response) => {
      this.getJobs();
    });
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
    clearInterval(this.intervalIdActive);
    // this.socket.off("job-progress"); // Remove the socket listener when the component is destroyed
  },
  watch: {
    pdfUrl() {
      this.pdfUrlError = false;
    },
    newTutorialEmail() {
      this.tutorialEmailsError = false;
    },
    selectedPlatform() {
      if (this.usersToSendReport.length > 0) {
        this.usersToSendReport = this.usersFromDB
          .filter(
            (user) =>
              this.usersToSendReport.includes(user.id) &&
              user.role == "ludus_admin"
          )
          .map((user) => user.id);
      }
    },
  },
  computed: {
    circumference() {
      // Calculate the circumference of the circle
      return 2 * Math.PI * this.radius;
    },
    users() {
      if (this.selectedPlatform) {
        return this.usersFromDB.filter(
          (user) =>
            user.platformId === this.selectedPlatform ||
            user.role == "ludus_admin" ||
            this.checkValidEmail(user)
        );
      } else {
        return this.usersFromDB.filter(
          (user) => user.role == "ludus_admin" || this.checkValidEmail(user)
        );
      }
    },
    appLanguage() {
      return this.$store.getters["getLanguage"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    checkValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      console.log("emailPattern", emailPattern);
      console.log(" emailPattern.test(email)", emailPattern.test(email));
      return emailPattern.test(email);
    },

    async validateUser(option) {
      console.log("option", option);
      if (option && option.email) {
        if (this.checkValidEmail(option.email)) {
          console.log(1);

          return option;
        } else {
          console.log(2);
          this.$toast.error("Email is not valid");
          return false;
        }
      }
    },

    //// userSelectedForReportsHandler(option) {
    //   console.log("option", option);
    //   if (!this.checkValidEmail(option)) {
    //     this.$refs.multiselectUsersToSendReport.deselect(option);
    //     const index = this.users.indexOf(option);
    //     if (index !== -1) {
    //       this.users.splice(index, 1);
    //     }
    //     // this.$refs.multiselectUsersToSendReport.updateItems();
    //     // this.users.pop();
    //     // console.log(
    //     //   "this.$refs.multiselectUsersToSendReport.options",
    //     //   this.users
    //     // );
    //     this.$toast.error("Please enter a valid email address.");
    //   }
    //   // this.$refs.multiselectUsersToSendReport.options.pop();
    // },

    expandJob(jobId, type, value) {
      const index = this.jobs[type].findIndex((job) => job.id === jobId);
      if (index !== -1) {
        this.jobs[type][index].expanded = value;
      }
    },
    calculateDashOffset(percentage) {
      // Calculate the dash offset based on the percentage
      const progress = percentage / 100;
      return this.circumference * (1 - progress);
    },
    updateElapsedTime() {
      // Update elapsed time for each job
      this.jobs.active.forEach((job) => {
        if (job.processedOn) {
          job.elapsedTime = this.calculateElapsedTime(job.processedOn);
        }
      });
    },
    calculateElapsedTime(startTime) {
      // Calculate the elapsed time since startTime
      const startTimestamp = new Date(startTime).getTime();
      const currentTimestamp = Date.now();
      const elapsedTimeInSeconds = Math.floor(
        (currentTimestamp - startTimestamp) / 1000
      );

      // Convert elapsed time to HH:mm:ss format
      const hours = Math.floor(elapsedTimeInSeconds / 3600);
      const minutes = Math.floor((elapsedTimeInSeconds % 3600) / 60);
      const seconds = elapsedTimeInSeconds % 60;
      if (hours > 0) {
        return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
      }
      if (minutes > 0) {
        return `${this.pad(minutes)}:${this.pad(seconds)}`;
      }
      return `${this.pad(seconds)}s`;
    },
    pad(num) {
      return num.toString().padStart(2, "0");
    },
    calculateTimeDifference(start, end) {
      if (!start || !end || isNaN(start) || isNaN(end)) {
        return "Invalid timestamps";
      }
      let difference = end - start;

      let seconds = Math.floor(difference / 1000);

      let hours = Math.floor(seconds / 3600);
      seconds %= 3600;
      let minutes = Math.floor(seconds / 60);
      seconds %= 60;

      const formattedTime = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(
        seconds
      )}`;

      return formattedTime;
    },
    timeLeft(timestamp1, millisecondsLeft) {
      const currentTimestamp = Date.now();
      const targetTimestamp = timestamp1 + millisecondsLeft;

      const difference = targetTimestamp - currentTimestamp;
      const hoursLeft = Math.floor(difference / (1000 * 60 * 60));
      const minutesLeft = Math.floor(
        (difference % (1000 * 60 * 60)) / (1000 * 60)
      );

      const formattedTime = `${hoursLeft
        .toString()
        .padStart(2, "0")}:${minutesLeft.toString().padStart(2, "0")}`;

      return formattedTime;
    },
    convertTimestamp(timestamp) {
      const millisecondsInHour = 3600000; // 1 hour in milliseconds
      const hours = Math.floor(timestamp / millisecondsInHour);
      const remainingMilliseconds = timestamp % millisecondsInHour;
      const minutes = Math.floor(remainingMilliseconds / 60000); // 1 minute = 60000 milliseconds

      if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ${minutes} minute${
          minutes > 1 ? "s" : ""
        }`;
      } else {
        return `${minutes} minute${minutes > 1 ? "s" : ""}`;
      }
    },
    clearDate(date) {
      const currentDate = new Date();
      const inputDate = new Date(date);
      const isToday = currentDate.toDateString() === inputDate.toDateString();

      let options;
      if (isToday) {
        options = {
          hour: "numeric",
          minute: "numeric",
        };
      } else {
        options = {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
      }
      return inputDate.toLocaleTimeString(this.appLanguage.long, options);
    },
    fullDate(date) {
      const inputDate = new Date(date);

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return inputDate.toLocaleTimeString(this.appLanguage.long, options);
    },
    selectAllUsers() {
      if (this.usersToSendReport.length === this.users.length) {
        this.usersToSendReport = [];
      } else {
        this.usersToSendReport = this.users.map((user) => user.email);
        // this.usersToSendReport = this.users.map((user) =>
        //   user.email ? user.email : null
        // );
      }
    },
    getTodayStart() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    getTodayEnd() {
      const today = new Date();
      today.setHours(23, 59, 59, 59);
      return today;
    },
    checkValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    addTutorialUser() {
      if (!this.checkValidEmail(this.newTutorialEmail)) {
        this.$toast.error("Please enter a valid email address.");
        return; // Stop execution if email is invalid
      }

      this.tutorialEmails.push(this.newTutorialEmail);
      this.newTutorialEmail = "";
    },
    changeLanguage(language) {
      this.$store.dispatch("setLanguageAction", {
        short: language.short,
        long: language.long,
      });
      this.$i18n.locale = language.short;
    },
    async clearWaitingJob(type, jobId) {
      const typeSplitted = type.split(":");
      const jobType = typeSplitted[1];
      try {
        await request.post(`jobs/clear/${jobType}/${jobId}`);
        const index = this.jobs.waiting.findIndex((job) => job.id == jobId);
        if (index !== -1) {
          this.jobs.waiting.splice(index, 1);
        }
        this.$toast.success("Job removed successfully");
      } catch (e) {
        console.log(e);
        this.$toast.error("Something went wrong: " + e.response.statusText);
      }
    },
    async getActiveJobs() {
      this.loadingActiveJobs = true;
      try {
        const bookkeepingActiveJobs = (
          await request.get("jobs/active/bookkeeping")
        ).data;
        const reportsActiveJobs = (await request.get("jobs/active/reports"))
          .data;
        const activeJobs = bookkeepingActiveJobs.concat(reportsActiveJobs);
        for (const job of activeJobs) {
          job.expanded = false;
        }
        this.jobs.active = activeJobs;
      } catch (e) {
        console.error(e.message);
      }
      this.loadingActiveJobs = false;
    },
    async getWaitingJobs() {
      this.loadingWaitingJobs = true;
      try {
        const bookkeepingWaitingJobs = (
          await request.get("jobs/waiting/bookkeeping")
        ).data;
        const reportsWaitingJobs = (await request.get("jobs/waiting/reports"))
          .data;
        const waitingJobs = bookkeepingWaitingJobs.concat(reportsWaitingJobs);
        for (const job of waitingJobs) {
          job.expanded = false;
        }
        this.jobs.waiting = waitingJobs;
      } catch (e) {
        console.error(e.message);
      }
      this.loadingWaitingJobs = false;
    },
    sortJobs(type, element) {
      this.jobs[type].sort(
        (a, b) => new Date(b[element]) - new Date(a[element])
      );
    },
    async getJobs() {
      try {
        const bookkeepingJobs = (await request.get("jobs/all/bookkeeping"))
          .data;
        const reportJobs = (await request.get("jobs/all/reports")).data;
        const jobs = {};
        jobs.active = bookkeepingJobs.active.concat(reportJobs.active);
        this.sortJobs("active", "finishedOn");
        jobs.waiting = bookkeepingJobs.waiting.concat(reportJobs.waiting);
        this.sortJobs("waiting", "finishedOn");
        jobs.completed = bookkeepingJobs.completed.concat(reportJobs.completed);
        this.sortJobs("completed", "finishedOn");
        jobs.delayed = bookkeepingJobs.delayed.concat(reportJobs.delayed);
        this.sortJobs("delayed", "timestamp");

        for (const job of Object.values(jobs)) {
          for (const childJob of job) {
            childJob.expanded = false;
          }
        }
        this.jobs = jobs;
      } catch (e) {
        console.error(e.message);
      }
    },
    async getUsers() {
      try {
        const users = (await request.get("users")).data;
        users.sort((a, b) => a.username.localeCompare(b.username));
        this.usersFromDB = users.map((user) => {
          return {
            ...user,
            username: user.platform
              ? `${user.username} - ${user.platform.name}`
              : `${user.username} - ${user.role}`,
          };
        });
      } catch (e) {
        console.log(e.message);
      }
    },
    async getAllPlatforms() {
      try {
        const platforms = (await request.get("/platforms")).data.rows;
        this.allPlatforms = platforms;
        this.allPlatforms.sort((a, b) => a.name.localeCompare(b.name));
      } catch (e) {
        console.log(e);
        this.serverError = true;
      }
    },
    async sendReport() {
      this.loadingReport = true;
      try {
        const dateObj = useDateFormatter(this.date, this.type);
        const requestBody = {
          ...dateObj,
          to: this.usersToSendReport,
          platformId: this.selectedPlatform,
        };
        let endpoint;
        console.log(this.type);
        switch (this.type) {
          case "daily":
            endpoint = `/reports-email-daily?socketId=${this.socket.id}`;
            break;

          case "monthly":
            endpoint = `/reports-email-monthly?socketId=${this.socket.id}`;
            break;

          default:
          case "custom": {
            endpoint = `/reports-email?socketId=${this.socket.id}`;
          }
        }

        console.log(endpoint);
        await request.post(endpoint, requestBody);
        this.$toast.success("Reports request created successfully");
        this.usersToSendReport = [];
        await this.getJobs();
      } catch (e) {
        console.log(e.message);
      }
      this.loadingReport = false;
    },
    async sendTutorialEmails() {
      // if(this.pdfUrl === '') {
      //   this.$toast.error('Please enter the PDF url')
      //   this.pdfUrlError = true
      // }
      if (this.newTutorialEmail !== "") {
        if (!this.checkValidEmail(this.newTutorialEmail)) {
          this.$toast.error("Please enter a valid email address.");
          return; // Stop execution if email is invalid
        } else {
          this.tutorialEmails.push(this.newTutorialEmail);
          this.newTutorialEmail = "";
        }
      }
      if (this.newTutorialEmail === "" && this.tutorialEmails.length === 0) {
        this.$toast.error("Please enter at least one email address");
        this.tutorialEmailsError = true;
        return;
      }
      this.loadingTutorial = true;

      try {
        await request.post("tutorial", {
          to: this.tutorialEmails,
          fileUrl: this.pdfUrl,
        });
        this.$toast.success("Emails sent successfully");
        this.tutorialEmails = [];
      } catch (e) {
        console.log(e.message);
      }
      this.tutorialEmails = [];
      this.loadingTutorial = false;
    },
  },
};
</script>
<style scoped>
</style>